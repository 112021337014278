import { ImportExport, PlusOne, Refresh, ThreeDRotation } from '@mui/icons-material';
import Alert from '@mui/lab/Alert';
import { AppBar, Box, Button, CircularProgress, Container, Divider, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import SEO from '../../components/common/seo';
import { Affordance, Map } from '../../types/mapper/maps';

// import '../../../../public/potree/potree.css';
import ImportExportMapDialog from '../../components/apps/boxingtool/ImportExportMapDialog';
import LogoDialog from '../../components/apps/boxingtool/LogoDialog';
import { loadPointcloud, PointcloudTypes } from '../../services/mapper/potree/loader';

// @ts-ignore
const Potree = typeof window !== `undefined` ? window.Potree : {};

// Temporary mocked Map - we'll bring this from the API.
const tempMap: Map = {
  id: 'blank',
  label: 'Example map',
  description: '',
  status: 'active',
  data: {
    dimensionality: '3d',
  },
  exportedMapId: 'hilti', //This will be loaded from 'public/pointclouds/[folder]/'
  affordances: [],
  annotations: [],
  createdTimestamp: '',
  lastUpdatedTimestamp: '',
  userRoles: [],
};

export default class Mapper extends React.Component {
  viewer: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.potreeContainerDiv = React.createRef();

    this.viewer = null;
    this.state = {
      selectedObject: undefined,
      loadingMap: false,
      loadingError: undefined,
      mapLoaded: undefined,
      importExportMapDialogOpen: false,
      logoDialogOpen: true,
    };
  }

  onVolumeCreateClicked() {
    this.viewer === null && console.error('Missing viewer!');
    const volume = this.viewer.volumeTool.startInsertion({
      // clip: true
    });
    // console.log(volume);
    // volume.addEventListener('drop', () => {
    //   console.log('Dropped');
    // });
  }

  onSelectionChanged(e: { selection: any; oldSelected: any; type: string }) {
    console.log('Selection Changed');
    console.log(e);

    if (e.selection.length > 0) {
      //Object selected
      console.log(`Selected ${e.selection[0]}`);
    } else {
      // Object deselected
      console.log('Deselected');
      //On deselect, save current changes from old selection.
    }
  }

  onRefreshClicked() {
    // Just for fun add another map a little over for testing...
    console.log('Refreshing!');
    this.loadMap(this.state.mapLoaded);
  }

  onShowImportExport() {
    //When showing it, copy the volumes to the affordances in the Map.

    const affordances = this.viewer.scene.volumes.map((vol) => {
      return {
        label: vol.name,
        position: vol.position,
        rotation: vol.rotation.toVector3(),
        scale: vol.scale,
      };
    });
    this.state.mapLoaded.affordances = affordances;
    this.setState(Object.assign(this.state, { importExportMapDialogOpen: true, mapLoaded: this.state.mapLoaded }));
  }

  async loadMap(mapToLoad: Map) {
    //Whatever happens, save the map.
    this.setState(Object.assign(this.state, { mapLoaded: mapToLoad }));
    this.setState(this.state);

    //Clear existing stuff..
    console.log(this.viewer.scene);
    while (this.viewer.scene.volumes.length > 0) {
      console.log('removing volume ' + this.viewer.scene.volumes[0]);
      this.viewer.scene.removeVolume(this.viewer.scene.volumes[0]);
    }
    //Remove pointclouds (need to do both arrays)
    while (this.viewer.scene.pointclouds.length > 0) {
      const index = this.viewer.scene.scenePointCloud.children.indexOf(this.viewer.scene.pointclouds[0]);
      this.viewer.scene.scenePointCloud.children.splice(index, 1);
      this.viewer.scene.pointclouds.splice(0, 1);
    }

    // Load and add point cloud to scene
    if (!mapToLoad.exportedMapId) {
      this.setState(
        Object.assign(this.state, {
          loadingMap: false,
          loadingError: 'The map does not have an exported pointcloud yet.',
        }),
      );
      return;
    }
    let url = `/apps/boxingtool/pointclouds/${mapToLoad.exportedMapId}/metadata.json`;

    // Set the volumes
    const volumes = this.state.mapLoaded.affordances.map((a: Affordance) => {
      const vol = new Potree.BoxVolume();
      vol.name = a.label;
      vol.clip = false;
      vol.position.set(a.position.x, a.position.y, a.position.z);
      vol.rotation.setFromVector3(a.rotation);
      vol.scale.set(a.scale.x, a.scale.y, a.scale.z);
      return vol;
    });
    console.log('Got volumes!');
    console.log(volumes);
    volumes.forEach((v) => {
      this.viewer.scene.addVolume(v);
      this.viewer.volumeTool.scene.add(v);
    });

    // Danial: I'm using Object.assign to update the state even though it's immutable. This is read as
    // set loadingMap = true in this.state and return a copy of the result.
    this.setState(Object.assign(this.state, { loadingMap: true }));
    // Danial: I'm using awaits here instead of promises. They're the same, basically, but this reads way easier.
    // Read as "do whatever until loadPointcloud is done, then continue below it."
    const result = await loadPointcloud(url, PointcloudTypes.POTREE);
    // If good, show it, else assign an error to the state...
    this.setState(Object.assign(this.state, { loadingMap: false }));
    console.log(result);

    if (result.error) {
      this.setState(Object.assign(this.state, { loadingMap: false, loadingError: result.error }));
    } else {
      let pointcloud = result.pointcloud;
      pointcloud.name = mapToLoad.label;
      // console.log("setting origin to " + map.origin.x);
      // pointcloud.position.set(map.origin.x,map.origin.y,map.origin.z);

      let material = pointcloud.material;
      material.size = 1;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
      material.shape = Potree.PointShape.SQUARE; //CIRCLE || PARABOLOID
      // Playing with blending.
      // material.color = new THREE.Color().setRGB(0.4, 0.6, 0.7);
      // material.blending = THREE.MultiplyBlending;
      // material.transparent = true;

      this.viewer.scene.addPointCloud(pointcloud);
      this.viewer.fitToScreen();
    }
  }

  async componentDidMount() {
    // @ts-ignore
    const viewerElem = this.potreeContainerDiv.current;

    const viewer = new Potree.Viewer(viewerElem);
    this.viewer = viewer;

    viewer.setEDLEnabled(true);
    viewer.setFOV(60);
    viewer.setPointBudget(1 * 1000 * 1000);
    viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    viewer.loadSettingsFromURL();

    viewer.setControls(viewer.orbitControls);

    // Add the event listeners to the default scene for objects.
    // this.viewer.addEventListener("update", (e) => {console.log(e)});
    // this.viewer.addEventListener("selection_changed", this.onSelectionChanged);
    this.viewer.inputHandler.addEventListener('selection_changed', this.onSelectionChanged);
    this.viewer.scene.addEventListener('volume_added', (e) => {
      console.log(e);
    });
    this.viewer.scene.addEventListener('volume_removed', (e) => {
      console.log(e);
    });

    //Right, load the map
    this.loadMap(tempMap);
  }

  render() {
    return (
      <Box>
        <SEO title="NavAbility Mapper" />
        {/* <Card>
          <CardHeader title="NavAbility Mapper" subheader="Test" />
          <CardContent style={{ padding: '0px', margin: '20px' }}></CardContent>
        </Card> */}
        <Container
          style={{
            maxWidth: 'none',
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {/* @ts-ignore */}
          <div
            ref={this.potreeContainerDiv}
            className={'potree_container '}
            style={{ height: 'calc(100vh - 190.517px)' }}
          >
            {/* <div id="potree_sidebar_container"> </div> */}
            <AppBar position="static">
              <Toolbar>
                <Button
                  onClick={() => this.onShowImportExport()}
                  startIcon={<ImportExport />}
                  variant="contained"
                  color="secondary"
                >
                  Import/Export Map
                </Button>

                <Divider orientation="vertical" style={{ margin: '10px' }} />

                <Button
                  onClick={() => {
                    this.onVolumeCreateClicked();
                  }}
                  startIcon={<PlusOne />}
                  variant="contained"
                  color="primary"
                >
                  Add Affordance
                </Button>

                <IconButton
                  onClick={() => {
                    this.viewer.fitToScreen();
                  }}
                  size="large">
                  <ThreeDRotation style={{ color: 'ffffff' }} />
                </IconButton>

                {/* Making a loading/refresh button. TODO: Compontentize these things. */}
                <Box style={{ margin: 1, position: 'relative' }}>
                  <Button
                    startIcon={<Refresh />}
                    variant="contained"
                    color="primary"
                    disabled={this.state.loadingMap}
                    onClick={() => {
                      this.onRefreshClicked();
                    }}
                  >
                    Refresh
                  </Button>
                  {this.state.loadingMap && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: '#ffffff',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Toolbar>
              {this.state.loadingError ? <Alert severity="error">{this.state.loadingError.toString()}</Alert> : null}
            </AppBar>
          </div>
        </Container>

        {/* Logo dialog */}
        {this.state.logoDialogOpen && (
          <LogoDialog
            onClose={() => {
              this.setState(Object.assign(this.state, { logoDialogOpen: false }));
            }}
          ></LogoDialog>
        )}

        {/* Import dialog */}
        {this.state.importExportMapDialogOpen && (
          <ImportExportMapDialog
            map={this.state.mapLoaded ?? tempMap}
            onClose={(map?: Map) => {
              this.setState(Object.assign(this.state, { importExportMapDialogOpen: false }));
              //If a map was returned, load it.
              map !== undefined && this.loadMap(map);
            }}
          />
        )}
      </Box>
    );
  }
}
