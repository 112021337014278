import { Cancel, Save } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import BootstrapDialogTitle from '../../common/dialogs/BootstrapDialogTitle';

interface Props {
  children?: React.ReactNode;
  onClose: () => void;
}

export default function LogoDialog(props: Props) {
  const { children, onClose, ...other } = props;

  return (
    <Dialog open={true} onClose={() => props.onClose()} fullWidth maxWidth="lg">
      <BootstrapDialogTitle id="dialog" onClose={props.onClose}>
        NavAbility Boxing Tool
      </BootstrapDialogTitle>
      <DialogContent>
        <img src="/apps/boxingtool/Logo.jpg" style={{width: "100%"}}></img>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<Cancel />}
          variant="contained"
          color="secondary"
          onClick={() => props.onClose()}
        >
          Jab
        </Button>
        <Button
          startIcon={<Save />}
          variant="contained"
          color="secondary"
          onClick={() => props.onClose()}
        >
          Right Hook
        </Button>
      </DialogActions>
    </Dialog>
  );
}
